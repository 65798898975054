
import { Component, Prop, Vue } from "vue-property-decorator";
import { RequestPaging } from "@/utils/util";

import { apiSupplierList, apiSetSupplierStatus } from "@/api/channel_provider";
@Component({
  components: {},
})
export default class SupplierList extends Vue {
  /** S Data **/

  pager: RequestPaging = new RequestPaging();
  searchObj: any = {
    id: "",
    type: "all",
    keywords: "",
  };

  recordData = [];
  created() {
    this.searchObj.id = this.$route.query.id;
    this.getLists();
  }
  async changeBind(row:any) {
    try {
      await apiSetSupplierStatus({
        id: this.$route.query.id,
        mch_id: row.mch_id,
        is_bind: row.is_bind,
      });
      this.getLists();
    } catch (e) {
      row.is_bind = !row.is_bind;
    }
  }
  // 获取商城列表
  async getLists() {
    this.recordData = await this.pager.request({
      callback: apiSupplierList,
      params: {
        ...this.searchObj,
        id: this.$route.query.id,
      },
    });
  }
}
